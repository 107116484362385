html,body {
	font-size:4vw;
	line-height: 6vw;
}
body { margin:2px;}
@media only screen and (min-width: 660px)  {
	html,body {
		font-size:26px;
		line-height:39px;
	}
	.App {
		width:640px;
		margin:auto;
	}
}
body,button {
	color: #333;
	font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
	font-weight: 400;
}
h2 {
	font-size:2rem;
	line-height:2rem;
	font-weight:200;
	margin-top:0.3rem;
	margin-bottom:1rem;
	text-align:left;
}
#loading-indicator {
	margin:3rem;
}
.App {
	text-align: center;
}
.App.test-mode:after {
	content: "TEST MODE";
	position: absolute;
	top: 0.4rem;
	left: -1.9rem;
	background: red;
	color: white;
	font-size: .4rem;
	font-weight: 600;
	transform: rotate(-45deg);
	padding: 0 2rem;
}
.App-header {
	display:flex;
	justify-content:space-around;
	position:relative;
	z-index:1;
	margin-bottom: 0.8rem;
}

.App-logo {
	width:28%;
}
.App-logo img { width:100%; filter: drop-shadow(1px 1px 1px #CCC); }

.App-header-text {
	display:flex;
	flex-direction:column;
	justify-content:center;
	flex-grow:1;
}

.title {
	text-align:center;
	font-size:1.25rem;
	font-weight:200;
}

.App-helpline {
	margin-bottom:0.5rem;
}

#game-header {
	text-align:center;
	color: #767676;
	font-size:65%;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	font-weight:200;
	line-height:1rem;
}
#game-header-teams { font-weight:bold; font-size:135%;}

#clickzone {
	position:relative;
	height:9rem;
	background:#7FDBFF; /* dark blue (border): #006c97    med blue (shadow): #00b7ff    light blue (bg): #7FDBFF */
	cursor:pointer;
	z-index:1;
}
#normaldist {
	position:absolute;
	bottom:0;
	height:38%;
	transition:height 0.7s cubic-bezier(0, 0, 0, 1);
	width:180%;
	mix-blend-mode: color-dodge;
}

#normaldist.hidden {
	height:0;
	transition:none;
}

.logo-box {
	width:5rem;
	height:3.3rem;
	position:absolute;
	top:-0.5em;
	z-index:10;
}

.logo-box img {
	width:100%;
	height:100%;
	object-fit:contain;
	filter:drop-shadow(2px 2px 2px #444);
}

.logo-box-home { left:4%;}
.logo-box-away { right:4%;}
.logo-box-home img { object-position:top left;}
.logo-box-away img { object-position:top right;}

#gamebackground {
	width:100%;
	height:50%;
	background:radial-gradient(ellipse at bottom, #7FDBFF 0%,#fff 75%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#gbg {
	width:100%;
	border-bottom:1px solid #006c97;
	box-shadow: 0 1px 1px #00b7ff;
}

.gbgsection {
	display:inline-block;
	width:7.69%;
	position:relative;
	font-size:0.75rem;
	font-weight:bold;
}

.gbgsection:after {
	content: "\25BE";
	position: absolute;
	font-size: 0.5rem;
	text-align: center;
	width: 100%;
	height: 100%;
	left:0;
	top:-0.25rem;
	transform:translateY(50%);
}

.gbgsection, .gbgsection:after {
	color:white;
	text-shadow:1px 1px 0px #7FDBFF;
	letter-spacing:-0.05rem;
	z-index:8;
}

#gamebackground:after {
	content:"";
	position:absolute;
	z-index: 1;
	top:0;
	bottom:0;
	left:50.1%;
	border-left:1px solid #7FDBFF;
	transform: translate(-50%);
}

.indicator {
	position:absolute;
	z-index:10;
	bottom:50%;
	transform: translate(-50%, 1.25em);
	font-size:1rem;
	color:white;
	text-shadow:2px 1px 1px #006c97;
}

#indicator-result { color: #ffe7b4 ; z-index:8; text-shadow:none; filter: drop-shadow(1px 1px 0.5px black);}
#indicator-past { color:#006c97; z-index:9; text-shadow:none;}
#tiptext {
	position:relative;
	top:25%;
	z-index:10;
	display:flex;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	font-size:1rem;
	position:relative;
	left:-0.1rem;
}
#tiptext b { margin:0 0.25rem; text-shadow:0 0 1px #999; width:2rem; border:1px solid #7fdbff; background:white;}
#tiptext.tiptext-help {
	text-transform: uppercase;
	display: inline-block;
	font-weight: bold;
	color: white;
	font-size: 1rem;
	text-shadow: 1px 1px 1px #00ceff;
}
.tiptext-logo-container {
	width:2rem;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
}
.tiptext-draw {
	font-weight:800;
}
.tinylogo { max-width:1.3rem; max-height:1rem; margin-right:0.1rem;}
#status-bar { margin:0.5rem 0; display:flex; justify-content:space-between; z-index:1; position:relative;}
.sbox {
	width:7.75rem;
	min-height:4rem;
	font-size:0.55rem;
	box-shadow:1px 1px 2px #b7dae0;
	cursor:pointer;
	transition:box-shadow 0.225s ease-out;
	background:radial-gradient(ellipse at top, #ddf1f9 0%,#fff 125%);
}
.sbox:hover:not(.disabled):not(:disabled) { box-shadow:3px 3px 2px #b7dae0; }
.sbox-small { width:5rem; }
.sbox-small-row .sbox-small { margin:auto 1rem; }
#status-bar section.sbox {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
#status-bar-commands {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.game-score { display:flex; align-items:center; justify-content:center; cursor:pointer;}
.aiTip { background:radial-gradient(ellipse at center, #7FDBFF 0%, rgba(255,255,255,0) 120%);}
.untipped {background:radial-gradient(ellipse at center, #DDD 0%, rgba(255,255,255,0) 120%);}
.actual-tip { background:radial-gradient(ellipse at center, #ffe7b4 0%, rgba(255,255,255,0) 120%);}
#status-bar header { text-transform: uppercase; font-size:0.6rem; letter-spacing:0.1rem; line-height:1rem;}
.last-tip-winner { font-weight:bold; }
.last-tip-teambox { display:flex; align-items:center; margin:0 0.25em;}
.last-tip-teamscore { margin: 0 0.15rem; }

.games-remaining { font-size:0.5rem; }
.num-games-remaining { font-weight: bold; font-size:0.5rem; margin-right:0.125rem;}

#manual-button, #actual-button { width:auto; min-width:4.2rem; position:absolute; bottom:0.2rem; min-height:2.0rem; box-shadow: 0.1rem 0.1rem 0.25em #2c8cb9; border: 1px solid transparent;}
#manual-button:hover, #actual-button:hover {border-color:#7eccea;}
#manual-button { left:0.2rem; }
#manualentry-game { position:relative; padding-top:5rem; text-align:center;}
#manualentry-game .react-numeric-input input { width:11rem; font-size:2rem !important;}

#actual-button { right:0.2rem; background: rgb(254 240 209 / 90%); }
#actual-button .actual-result { display: flex; align-items: center; justify-content: center;}
#actual-button .tinylogo { max-width: 1rem; max-height: 0.8rem;}
#actual-button .smalltext { font-size:80%; font-weight: 200; margin: 0 0.18rem;}

#liveladder { width:100%; font-size:0.7rem;}
#liveladder .liveladder-row { display:block; width:100%; border:1px solid transparent; line-height:0.875rem;}
#liveladder-tbody .liveladder-row:hover {background:#444; color:white;}
#liveladder .liveladder-row div { display:inline-block; padding:0 0.1rem; width:1.7rem; }
#liveladder .liveladder-row div:nth-child(1) { width:1rem; }
#liveladder .liveladder-row div:nth-child(3) { text-align:left; width:9.3rem;}
#liveladder .liveladder-row div:nth-child(7) { width:2.3rem; }
#liveladder .liveladder-row div:nth-child(8) { width:0.67rem; }
#liveladder .liveladder-row.top8 div:nth-child(3) { font-weight:bold;}
#liveladder-tbody .liveladder-row div:nth-child(1) { border:1px solid #444; font-weight:bold; background:white;}
#liveladder-tbody .liveladder-row.top8 div:nth-child(1) { color:white; background:#444; }
#liveladder-thead { font-weight:bold; color:#444;}
#laddertext { margin-top:0.25rem; font-size:0.6rem;}
#liveladder .logo { max-height:0.5rem; margin-left:50%; transform:translateX(-50%);}
.ladderteamlogo { max-width:0.75rem; max-height:0.625rem; }
#liveladder .liveladder-row.highlight { background:linear-gradient(270deg, transparent 4%, #a9e9ff 10%, #7cddff 30%, #e8f5ff 96%, transparent 98%);}
#finals { margin: 0.6rem 0 2rem 0;}
#finals .finalsweek { display:flex; justify-content:space-around; margin:0.2rem auto; }
#finals .finalsbox { border:2px solid #444; border-radius:.3rem; display:flex;     height: 3rem; width: 5.6rem; position:relative;     box-shadow: 0 0 3rem black inset; margin:0 0.2rem;}
#finals .finalsbox:after { content: "v"; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); background: #444; color: #FFF; border-radius: 1rem; font-size: .6rem; font-weight: bold; line-height: 0.5rem; padding:0.1rem 0.2rem 0.2rem 0.2rem; text-shadow:0 0 0.1rem white;}
#finals .finalsboxteam { overflow:hidden; width:100%; position: relative; cursor:pointer; transition:all 0.25s ease-in-out; opacity:0.5; filter:grayscale(100); }
#finals .finalsboxteam img { max-width: 140%; max-height: 125%; transform: translate(-50%,0%); left: 50%; top: 0; position: absolute; filter:drop-shadow(2px 2px 2px #444); }
#finals .finalsboxteam:nth-child(1) { border-right: 0.05rem solid #333; border-radius:.3rem 0 0 .3rem;}
#finals .finalsboxteam:nth-child(2) { border-left:  0.05rem solid #333; border-radius:0 .3rem .3rem 0;}
#finals .finalsboxteam:hover, #finals .finalsboxteam.winner { filter:none; opacity:1;}
#finals .finalsweek-3 { justify-content:center; }
#finals .finalsweek-4 .finalsboxteam.winner { overflow:visible; }
#finals .finalsweek-4 .finalsboxteam.winner img { max-width:175%; max-height:150%; top:-12%; z-index:2; }
.finalslabel { font-size:0.5rem; font-weight:200; line-height:0.6rem; background:#68cdf8; width:2rem; margin-left:50%; transform:translateX(-50%); color:white; border-radius:.2rem .2rem 0 0; }
.finals-system a { font-size: 50%; font-weight: 300; text-decoration: none; color: #222; padding-bottom: 0.1em; border-bottom: 1px dotted #444;}

button.sbox, select.sbox { border:none; color:#444; min-height:1.75rem; }
button.sbox.selected, select.sbox.selected { border:2px solid #2c8cb9;}
button.sbox.disabled, select.sbox:disabled { filter:grayscale(100); color:inherit; cursor: default;}
select.sbox { width:auto; padding:0 0.6rem;}

.command { text-transform:uppercase; font-weight:600;}

.team-filter-box { border:1px solid #999; padding:0.25rem 0 0.5rem 0;}
.team-filter-box legend { text-align:left; margin-left:0.5rem; font-size:0.425rem; text-transform:uppercase; }
.team-filter { display:flex; flex-wrap:wrap; justify-content:center; align-items:center;}
.team-filter-logo  { opacity:0.5; transition:opacity 0.2s ease-out; cursor:pointer; filter:saturate(0); border-bottom:2px solid transparent; height:2rem; display: flex; align-items: center;}
.team-filter-logo img.tinylogo { max-width:2.5rem; max-height:2rem;}
.team-filter-logo:hover { border-bottom-color:#444;}
.team-filter-logo.selected { opacity:1; filter:none;}

#fixture-list-games { font-size:0.6rem; width:100%; }
#fixture-list-games .fixture-row { display:flex; align-items:center; margin-bottom:0.25rem;}
#fixture-list-games .fixture-cell { padding:0 0.1rem; }
#fixture-list-games .fixture-row .fixture-cell:nth-child(1) { width:10%; text-align:center; }
#fixture-list-games .fixture-row .fixture-cell:nth-child(2) { width:40%; }
#fixture-list-games .fixture-row .fixture-cell:nth-child(3) { width:45%; padding-left:4%;}
#fixture-list-games-thead { font-weight:bold;}
#fixture-list-games-tbody .fixture-row { cursor:pointer; }
#fixture-list-games-tbody .fixture-row:hover { background:#444; color:white; }
#fixture-key { display: flex; justify-content: flex-end; align-items:center; font-size:0.5rem; line-height:100%; color:#444;}
#fixture-key div { padding:0.3rem; margin:0.2rem; }

.allow-tipping-past { text-align: right; margin: 0.4rem 0; font-size: 0.5rem; color: #444; }
.allow-tipping-past p { margin: 0; line-height: 130%; }
.allow-tipping-past p label { display: inline-flex; align-items: center; margin-bottom: 1em; }
.allow-tipping-past-year-disabled { color: #AAA; }
#reset-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}
#reset-buttons .sbox {
    height: 2.5rem;
	margin: 1rem;
}

.help-section p { font-size:0.75rem; line-height:180%;}
.help-section header p { font-weight: 600; background: #444; color: white; padding-left: 0.25rem;}
.help-new { float: right; font-size: 85%; margin-right:0.5em; }
.ModalContent .closebox {
	float:right;
}

.ModalOverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;

	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
	height:100%;
}

.ModalContent {
	position: absolute;
	top: 4vh;
	left: 4vw;
	right: 4vw;
	height:92vh;
	background:white;
	overflow-x: none;
	outline: none;
	padding:2vh 2vw;
	box-shadow:2px 2px 6px #222;

	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.ModalContentSmall {
	height:auto;
	text-align:center;
}

.ReactModal__Content {
	opacity: 0;
}

.ReactModal__Content--after-open {
	opacity: 1;
	transition: opacity 150ms;
}

.ReactModal__Content--before-close {
	opacity: 0;
}

.pcenter { text-align:center;}
.twitter-follow-button {
	padding:0.1rem 0.25rem;
	margin:0.25rem;
	background-color: #1b95e0;
	color: #fff;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}
.twitter-follow-button i {
	position:relative;
	top:0.125rem;
	display:inline-block;
	width:0.6rem;
	height:0.6rem;
	margin-right:0.25rem;
	background:transparent 0 0 no-repeat;
	background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
}
#page-footer {
	margin:1rem;
	text-align:left;
	font-size:0.6rem;
	position:relative;
	z-index:1;
}
#page-footer a{
	color:#444;
	text-decoration:none;
}
#page-footer .maxbarry-link a:hover {
	text-decoration:underline;
}
#page-footer .maxbarry-link {
	text-align:center;
	font-weight:100;
}
#page-footer .rate-my-ladder-link {
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	width:auto;
	line-height:1rem;
	overflow:hidden;
	padding:0 0.5rem;
}
#page-footer .rml-title {
	font-size:1rem;
	font-weight:400;
	margin-bottom:0.3rem;
}
#page-footer .explanatory-text {
	font-size:80%;
	line-height:1.5;
}
#page-footer .rate-my-ladder-link img {
	max-width: 52%;
    margin-right: 1rem;
    box-shadow: 1px 1px 3px #255363, 0 0 24px #55b1da;
}
#page-footer .rate-my-ladder-link:after {
    content: "NEW";
    position: absolute;
    top: 0.45rem;
    left: -1.6rem;
    color: white;
    background: #00b5f2;
    transform: rotate(-45deg);
    padding: 0 2rem;
    font-weight: 800;
	box-shadow: 0px 0px 2px #00b5f2;
}

@media only screen and (min-width: 780px)  {
	.ModalContent {
		width:740px;
		margin:auto;
	}

	#manual-button, #actual-button { min-height:2.2rem; }
}

@media only screen and (min-width: 1100px)  {
	.App {
		padding-right:640px;
	}
	#LP-ladder {
		position: absolute;
		top:0;
		right:auto;
		padding-left:640px;
		width:640px;
		margin:0.5rem auto;
	}
}
